import {LitElement, html, css} from 'lit';

/**
 * Intersection observer to animate and make the entries viewable
 */
 let interSectionObserver = new IntersectionObserver(function(entries) {
    entries.forEach(function(entry) {
      if(entry.isIntersecting === true){
        entry.target.classList.add("entry--open")
        interSectionObserver.unobserve(entry.target);
      }
    });
  }, { threshold: [0] });

export class ProjectElement extends LitElement {
    static get styles() {
        return css`
        .entry {
            width: 100%;
            box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
            background-color: var(--panel-heading-bg-color, #141414);
            display: block;
        }

        @media only screen and (min-width: 920px) {
            .entry {
                display: flex;
                flex-wrap: nowrap;
                flex-direction: column;
                justify-content: flex-start;
                box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.2);
                border:none;
                border-radius: var(--border-radius-base, 6px);
                background-color: var(--panel-bg-color, #141414);
                transition: all 0.2s ease-in-out;
                height: 100%;
            }
            .entry__image {
                max-width: 260px;
                height: auto;
            }
        }

        .entry__image-link {
            display: block;
            width: 100%;
        }
        
        .entry__image {
            width: 100%;
            border-top-left-radius: var(--border-radius-base, 6px);
            border-top-right-radius: var(--border-radius-base, 6px);
        }
        
        .entry__body {
            padding: 15px;
            padding-top: 5px;
        }

        .entry__body .entry__title {
            padding: 0;
        }
        
        .entry__title {
            width: 100%;
            margin: 0;
            padding: 0px 10px 10px 10px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 16px;
        }
        
        .entry__title .username .icon {
            margin-left: 0;
            height: 12px;
        }
        

        .entry__heading {
            position: relative;
        }

        project-collection-button-element {
            transition: 0.2s ease-in-out;
            opacity: 0;
            position: absolute;
            left: 0.25em;
            top: 0.2em;
            font-size: 1.4em;
        }

        .entry:hover project-collection-button-element {
            opacity: 1;
        }

        .entry:hover {
          box-shadow: 0px 16px 17px 0px rgb(0, 0, 0);
          translate: 0em -1em;
        }

        /* tags */

        .entry__tags {
            width: 100%;
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-around;
        }
        
        .entry__tags .entry__tag:first-child {
            border-bottom-left-radius: var(--border-radius-base, 6px);
        }
        
        .entry__tags .entry__tag:last-child {
            border-bottom-right-radius: var(--border-radius-base, 6px);
        }
        
        .entry__tag {
            width: 100%;
            text-align: center;
            background: var(--tag-background, linear-gradient(#322B1C, #4A402B));
            color: var(--tag-color, #fff);
        }
        
        .entry__tag--nsfw {
            background: var(--tag-background-nsfw, linear-gradient(#471c1c, #662f2f));
            color: var(--tag-color, #fff);
        }
        
        .entry__tag--furry {
            background: var(--tag-background-furry, linear-gradient(#1b471b, #2e662e));
            color: var(--tag-color, #fff);
        }
        
        .entry__tag--mod {
            background: var(--tag-background-mod, linear-gradient(#1b4347, #2e6166));
            color: var(--tag-color, #fff);
        }
        
        .entry__tag--beta {
            background: var(--tag-background-beta, linear-gradient(#47451b, #66632e));
            color: var(--tag-color, #fff);
        }
        
        .entry__tag--tutorial {
            background: var(--panel-heading-bg-color, #272727);
            color: var(--tag-color, #fff);
            padding: 5px;
        }

        a {
            color: var(--link-color, #A89368); 
            text-decoration: none;
        }
        
        a:visited {
            color: var(--link-color, #A89368); 
        }
        
        a:hover {
            text-decoration: underline;
            color: var(--link-color-hover, #A89368);
        }
        `;
    }

    static get properties() {
        return {
            project_id: {type: String},
            project_name: {type: String},
            project_thumb: {
                type: Object,
                converter: {
                    fromAttribute: (value, type) => {
                        if (typeof value === 'string' && value.length > 0) {
                            return JSON.parse(value);
                        }
                        if (typeof value === 'object') {
                            return value;
                        }
                        return {};
                    },
                    toAttribute: (value, type) => {
                        return JSON.stringify(value);
                    }
                }
            },
            user_id: {type: Number},
            username: {type: String},
            profilename: {type: String},
            moderation: {type: Boolean,
                hasChanged(newVal, oldVal) {
                  return newVal !== oldVal;
                },
                converter: {
                  fromAttribute: (value, type) => {
                    if (value === 'true' || value === 'True' || value == '1' ) {
                      return true;
                    }
                    return false;
                  }
                }
            },
            nsfw: {type: Boolean,
                hasChanged(newVal, oldVal) {
                  return newVal !== oldVal;
                },
                converter: {
                  fromAttribute: (value, type) => {
                    if (value === 'true' || value === 'True' || value == '1' ) {
                      return true;
                    }
                    return false;
                  }
                }
            },
            furry: {type: Boolean,
                hasChanged(newVal, oldVal) {
                  return newVal !== oldVal;
                },
                converter: {
                  fromAttribute: (value, type) => {
                    if (value === 'true' || value === 'True' || value == '1' ) {
                      return true;
                    }
                    return false;
                  }
                }
            },
            staff: {type: Boolean,
                hasChanged(newVal, oldVal) {
                  return newVal !== oldVal;
                },
                converter: {
                  fromAttribute: (value, type) => {
                    if (value === 'true' || value === 'True' || value == '1' ) {
                      return true;
                    }
                    return false;
                  }
                }
            },
            patron: {type: Boolean,
                hasChanged(newVal, oldVal) {
                  return newVal !== oldVal;
                },
                converter: {
                  fromAttribute: (value, type) => {
                    if (value === 'true' || value === 'True' || value == '1' ) {
                      return true;
                    }
                    return false;
                  }
                }
            },
            verified: {type: Boolean,
                hasChanged(newVal, oldVal) {
                  return newVal !== oldVal;
                },
                converter: {
                  fromAttribute: (value, type) => {
                    if (value === 'true' || value === 'True' || value == '1' ) {
                      return true;
                    }
                    return false;
                  }
                }
            },
            showCollectionButton: {type: Boolean,
                hasChanged(newVal, oldVal) {
                  return newVal !== oldVal;
                },
                converter: {
                  fromAttribute: (value, type) => {
                    if (value === 'true' || value === 'True' || value == '1' ) {
                      return true;
                    }
                    return false;
                  }
                }
            },
        };
    }
    
    constructor() {
        super();
        this.project_id = '';
        this.project_name = '';
        this.project_thumb = {};
        this.user_id = 0;
        this.username = '';
        this.profilename = '';
        this.nsfw = false;
        this.furry = false;
        this.staff = false;
        this.patron = false;
        this.verified = false;
        this.showCollectionButton = false;
    }

    renderTagPartial() {
        var tagPartials = [];

        if (this.nsfw) {
            tagPartials.push(html`<span class="entry__tag entry__tag--nsfw">18+</span>`);
        }

        if (this.furry) {
            tagPartials.push(html`<span class="entry__tag entry__tag--furry">F</span>`);
        }

        if (this.moderation) {
            tagPartials.push(html`<span class="entry__tag entry__tag--mod">M</span>`);
        }

        return tagPartials;
    }

    getCollectionButton() {
      if(this.showCollectionButton) {
        return html`<project-collection-button-element project_id="${this.project_id}"></project-collection-button-element>`;
      }
      return html``;
    }

    render() {
        return html`
        <div class="entry">
            <div class="entry__heading" style="padding: 0px;">
              ${this.getCollectionButton()}
              <a href="/project/${this.project_id}/" class="entry__image-link">
                  ${this.project_thumb ? this.renderSourceSetEntry() : html`<img src="https://s3.eu-central-1.wasabisys.com/open3dlab-static/img/thumb_512.png" alt="${this.project_name}" class="entry__image">`}
              </a>
            </div>
            <div class="entry__body">
                <h3 class="entry__title" title="${this.project_name}">
                    <a href="/project/${this.project_id}/">${this.project_name}</a><br>
                    <small>by <a href="/user/${this.user_id}/" class="alert-link"><username-element username="${this.username}" profilename="${this.profilename}" title="${this.username}" staff="${this.staff}" patron="${this.patron}" verified="${this.verified}">${this.username}</username-element>
</a></small>
                </h3>
            </div>
            
            <div class="entry__tags">
                ${this.renderTagPartial()}
            </div>
          </div>
        `;
    }

    renderSourceSetEntry() {
        if (typeof this.project_thumb === 'string' && this.project_thumb.length > 0) {
            this.project_thumb = JSON.parse(this.project_thumb);
        }
        if (Object.keys(this.project_thumb).length === 0 || this.project_thumb.ratios['1/1'].sources['image/webp']['266'] === undefined) {
            return html`<img src="https://s3.eu-central-1.wasabisys.com/open3dlab-static/img/thumb_512.png" alt="${this.project_name}" class="entry__image">`;
        }
        return html`
            <img 
                    src="${this.project_thumb.ratios['1/1'].sources['image/webp']['266']}"
                    alt="${this.project_name}"
                    class="entry__image"
            />`;

    }
}

window.customElements.define('project-element', ProjectElement);


/**
 * Load comments when the page is ready
 */
 document.addEventListener("DOMContentLoaded", function(event) {
    let projectElements = document.getElementsByTagName('project-element');
    if (projectElements.length > 0) {
        for (let i = 0; i < projectElements.length; i++) {
            interSectionObserver.observe(projectElements[i]);
        }
    }
 });