import {LitElement, html, css} from 'lit';

export class CategorySelectElement extends LitElement {
    static get styles() {
        return css`
            :host {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            .toggle-switch-element-title {
                font-size: 1.3em;
                margin-right: 0.5em;
                font-weight: bold;
            }


            .search-options_sort {
                display: flex;
                gap: 1em;
                padding: 0.6em;
                align-items: center;
                flex-wrap: wrap;
                align-content: stretch;
            }

            select {
                width: 100%;
                font-size: 1.4em;
                border: 1px solid #000;
                background-color: var(--input-bg-color,#222222);
                color: var(--input-color,#FFF);
                padding: 8px 12px;
                flex-grow: 1;
            }

            @media only screen and (min-width: 920px) {
                :host {
                    width: auto;
                    min-width: 420px;
                }
                select {
                    width: 100%;
                    font-size: 1.2em;
                }
            }
        `;
    }
    
    static get properties() {
        return {
            title: {type: String},
            selectionData: {type: Array}
        };
    }

    constructor() {
        super();
        this.title = "";
        this.selectionData = [];
    }

    getOptionByTemplate(item) {
        return html`
            <option value="${item.pk}">${item.title}</option>
        `;
    }

    render() {
        return html`
            <div class="toggle-switch-element-title">
                ${this.title}:
            </div>

            <select name="order_by" id="id_order_by" @change="${this._selectCategory}">
                <option value="">Any</option>
                ${this.selectionData.map(
                    (item) => {
                        return this.getOptionByTemplate(item)
                    }
                )}
            </select>
        `;
    }

    _selectCategory(e) {
        this.dispatchEvent(new CustomEvent('categorychanged', {
            detail: {
                category: e.target.value
            }
        }));
    }

    _onLoad = () => {
        this._runFetch(this._processData);
    }

    _processData = (data) => {
        this.selectionData = data.results;
    }

    _runFetch = (callback) => {
        var categoryUrl = '/api/category/list/?format=json';

        var myInit = {
            method: 'GET'
        };

        var myRequest = new Request(categoryUrl, myInit);

        fetch(myRequest).then(
            responseData => responseData.json()
        ).then(
            responseData => callback(responseData)
        ).catch(function (e) {
            console.log(e);
        });
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('DOMContentLoaded', this._onLoad);
    }
    disconnectedCallback() {
        window.removeEventListener('DOMContentLoaded', this._onLoad);
        super.disconnectedCallback();
    }
}

window.customElements.define('category-select-element', CategorySelectElement);