import {LitElement, html, css} from 'lit';

/**
 * Intersection observer to animate and make the entries viewable
 */
 let collectionInterSectionObserver = new IntersectionObserver(function(entries) {
    entries.forEach(function(entry) {
      if(entry.isIntersecting === true){
        entry.target.classList.add("entry--open")
        collectionInterSectionObserver.unobserve(entry.target);
      }
    });
  }, { threshold: [0] });

  export class CollectionListElement extends LitElement {
    static get styles() {
        return css`
            :host {
                min-height: 400px;
                user-select: none;
                margin-top: 0.5em;
            }
            .collection-list {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(min(230px, 100%), 1fr));
                grid-template-rows: repeat(auto-fill, minmax(200px, 1fr));
                gap: 1rem;
                margin-bottom: 0.5em;
            }

            collection-element  {
                width: 100%;
                translate: 0em 4em;
                opacity: 0;
                transition: 0.2s ease-in-out;
            }
            
            collection-element.entry--open {
                display: block;
                opacity: 1;
                transform: scaleY(1);
                translate: 0em 0em;
            }

            /* empty state */
            .empty-state {
                display: block;
                width: 100%;
                text-align: center;
                font-size: 1.4em;
                margin-top: 1em;
                margin-bottom: 1em;
            }

            /* loading state */
            .loading-state {
                display: flex;
                width: 100%;
                text-align: center;
                font-size: 1.4em;
                font-weight: bold;
                margin-top: 3em;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: center;
            }

            
            @keyframes animation {
                0% {
                stroke-dasharray: 1 98;
                stroke-dashoffset: -105;
                }
                50% {
                stroke-dasharray: 80 10;
                stroke-dashoffset: -160;
                }
                100% {
                stroke-dasharray: 1 98;
                stroke-dashoffset: -300;
                }
            }
            
            .loading-state svg {
                width: 25px;
                height: 25px;
                margin-right: 0.5em;
            }
            
            #loading-spinner {
                transform-origin: center;
                animation-name: animation;
                animation-duration: 1.2s;
                animation-timing-function: cubic-bezier;
                animation-iteration-count: infinite;
            }

            .search-row {
                display: flex;
                flex-direction: column;
                gap: 1em;
                margin-bottom: 0.5em;
                width: 100%;
            }
            .search-row--horizontal {
                flex-direction: row;
                align-items: center;
                justify-content: right;
                flex-shrink: 2;
            }
            @media only screen and (min-width: 920px) {
                .search-row {
                    flex-direction: row;
                }
            }

            .search-options {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin: auto;
                flex-wrap: wrap;
                font-size: 1.2em;
                box-sizing: border-box;
                width: 100%;
            }

            .search-options_input {
                flex-grow: 2;
                padding-right: 2em;
            }
            .search-options-selected {
                display: flex;
                flex-grow: 2;
                flex-direction: row;
                flex-wrap: wrap;
                width: 100%;
                min-height: 60px;

                background-color: var(--input-bg-color,#222222);
                border-radius: 0.2em;
                box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.75);
            }
            .search-options-selected p {
                margin-left: 1em;
                margin-right: 1em;
                color: #605E5B;
            }
            
            @media only screen and (min-width: 920px) {
                .search-options-selected p {
                    color: #605E5B;
                }
            }
            a, a:hover, a:visited {
                color: var(--link-color,#A89368);
                text-decoration: none;
              }
              a:hover {
                text-decoration: underline;
                color: var(--link-color-hover,#A89368);
              }
              a:visited {
                color: var(--link-color,#A89368);
              }
        `;
    }

    static get properties() {
        return {
            isLoading: {type: Boolean, state: true},
            collectionArray: {type: Array, state: true},
            collectionTotal: {type: Number, state: true},
            limit: {type: Number},
            collectionOffset: {type: Number, state: true},
            sortingMode: {type: String, state: true},
            currentPage: {type: Number, state: true},
            totalPages: {type: Number, state: true},
            showpagination: {type: Boolean,
                hasChanged(newVal, oldVal) {
                  return newVal !== oldVal;
                },
                converter: {
                    fromAttribute: (value, type) => {
                        if (value === 'true' || value === 'True' || value == '1' || value === true) {
                            return true;
                        }
                        if (value === 'false' || value === 'False' || value == '0' || value === false ) {
                            return false;
                        }
                        return false;
                    }
                }
            },
            nsfw: {type: Boolean,
                hasChanged(newVal, oldVal) {
                  return newVal !== oldVal;
                },
                converter: {
                    fromAttribute: (value, type) => {
                        if (value === 'true' || value === 'True' || value == '1' || value === true) {
                            return true;
                        }
                        if (value === 'false' || value === 'False' || value == '0' || value === false ) {
                            return false;
                        }
                        return false;
                    }
                }
            },
        }
    }

    constructor() {
        super();
        this.isLoading = true;
        this.collectionArray = [];
        this.collectionTotal = 0;
        this.limit = 24;
        this.collectionOffset = 0;
        this.sortingMode = '';
        this.currentPage = 1;
        this.totalPages = 1;
        this.showpagination = true;
        this.nsfw = false;
    }

    getItemTemplate(data) {
        return html`
            <collection-element class="entry" collection_id="${data.pk}" collection_name="${data.name}" collection_thumb="${JSON.stringify(data.item_thumb)}" user_id="${data.author.user_id}" username="${data.author.username}" profilename="${data.author.profile_name}" staff="${data.author.staff}" patron="${data.author.paid_supporter}" verified="${data.author.verified_uploader}" nsfw="${data.nsfw}" shownsfw="${this.nsfw}"></collection-element >
        `;
    }

    getItems() {
        return html`${this.collectionArray.map(
            (data) => {
                return this.getItemTemplate(data)
            }
        )}`;
    }

    render() {
        let returnArray = [];

        if (this.showpagination) {
            returnArray.push(html`
                <pagination-element page="${this.currentPage}" totalPages="${this.totalPages}" @pagechange=${this._pageChanged}></pagination-element>
            `);
        }


        if (this.collectionArray && this.collectionArray.length > 0) {
            returnArray.push(html`
                <div class="collection-list">
                    ${this.getItems()}
                </div>
            `);
        }

        if (this.collectionArray && this.collectionArray.length === 0) {
            if (this.isLoading) {
                returnArray.push(html`
                    <loading-element></loading-element>
                `);
            } else {
                returnArray.push(html`
                    <div class="empty-state">
                        <h4>No lists found</h4>
                        ${this.showpagination ? html`<p>Try adjusting your search terms.</p>` : ''}
                    </div>
                `);
            }
        }

        if (this.showpagination) {
            returnArray.push(html`
                <pagination-element page="${this.currentPage}" totalPages="${this.totalPages}" @pagechange=${this._pageChanged}></pagination-element>
            `);
        }

        return returnArray;
    }

    // Pagination event handlers
    _pageChanged = (event) => {
        if (event.detail.page !== this.currentPage && event.detail.page >= 1 && event.detail.page <= this.totalPages) {
            this.currentPage = event.detail.page ?? 1;

            const url = new URL(window.location);
            url.searchParams.set('page', this.currentPage);
            window.history.pushState({}, '', url);

            this._fetchItemData(this._processItemData);
        }
    }


    _loadItems = () => {
        const url = new URL(window.location);

        this._fetchItemData(this._processItemData);
    }

    _processItemData = (response) => {
        this.collectionTotal = response.count;
        this.collectionArray = response.results;
        this.totalPages = Math.ceil(this.collectionTotal / this.limit);
        this.isLoading = false;
        if (this.totalPages < this.currentPage) {
            this.currentPage = this.totalPages;
            this._fetchItemData(this._processItemData);
        }
    }

    _fetchItemData = (callback) => {
        if (this.collectionArray.length > 0) {
            this._clearItemsAnimate().then(() => {
                this._runFetch(callback);
            });
        } else {
            this._runFetch(callback);
        }
    }

    _clearItemsAnimate() {
        return new Promise((resolve) => {
            let collectionElements = this.shadowRoot.querySelectorAll('.entry');
            if (collectionElements.length > 0) {
                for (let i = 0; i < collectionElements.length; i++) {
                    collectionElements[i].classList.remove('entry--open');
                }
            }
            resolve();
        });
    }
    
    _runFetch(callback) {
        this.collectionOffset = (this.currentPage - 1) * this.limit;

        var collectionUrl = '/api/collection/list/?format=json&limit=' + this.limit + '&offset=' + this.collectionOffset;

        var myInit = {
            method: 'GET'
        };

        var myRequest = new Request(collectionUrl, myInit);

        fetch(myRequest).then(
            responseData => responseData.json()
        ).then(
            responseData => callback(responseData)
        ).catch(function (e) {
            console.log(e);
        });
    }


    updated(changedProperties) {
        if (changedProperties.has('collectionArray')) {
          this._itemElementLoaded();
        }
    }

    _itemElementLoaded() {
        let projectElements = this.shadowRoot.querySelectorAll('.entry');
        if (projectElements.length > 0) {
            for (let i = 0; i < projectElements.length; i++) {
                collectionInterSectionObserver.observe(projectElements[i]);
            }
        }
    }

    connectedCallback() {
        super.connectedCallback();
        window.addEventListener('DOMContentLoaded', this._loadItems);
        window.addEventListener('popstate', this._loadItems);
    }
    disconnectedCallback() {
        window.removeEventListener('popstate', this._loadItems);
        window.removeEventListener('DOMContentLoaded', this._loadItems);
        super.disconnectedCallback();
    }
}
window.customElements.define('collection-list-element', CollectionListElement);